<template>
  <div class="footer">
    <div class="footer-part1">
      <div class="footer-logo-content">
        <img :src="logo" class="logo-hd" />
        <ul class="channel-lists">
          <li :key="index" v-for="(item, index) in channelList.slice(0, 6)">
            <img :src="item" />
          </li>
        </ul>
        <p v-if="!isMobile" class="footer-part2">
          {{ copyRight }}
        </p>
      </div>
      <div class="footer-part1-2">
        <div class="links-more more-wrapper">
          <h3 class="title">{{ brandName }}</h3>
          <ul class="links-more-lists">
            <li>
              <a @click="jump('rule')" href="javascript:;">Rules of Conduct</a>
            </li>
            <li>
              <a @click="jump('term')" href="javascript:;">Terms of service</a>
            </li>
            <li>
              <a @click="jump('policies')" href="javascript:;">Privacy policy</a>
            </li>
          </ul>
        </div>
        <div class="contact-more more-wrapper">
          <h3 class="title">Contact Us</h3>
          <ul class="contact-lists">
            <li>
              <p>Email: {{ email }}</p>
            </li>
            <li>
              <p>Contact Center: +62 082297433638</p>
            </li>
            <li>
              <p>Office Address: {{ address }}</p>
            </li>
          </ul>
        </div>
        <p v-if="isMobile" class="footer-part2">
          {{ copyRight }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import env from "../assets/js/env";
export default {
  data() {
    return {
      logo: require('../assets/image/kuku-logo.png'),
      isMobile: false,
      companyName: env.companyName,
      brandName: env.brandName,
      email: env.email,
      address: env.address,
      copyRight: env.copyRight,
      channelList: [
      ],
      channelMobileList: [
      ],
      currency: this.$store.state.orderInfo.gameCurrency,
    }
  },
  mounted() {
    this.loadChannelList()
    if (this._isMobile()) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    jump(path) {
      this.$router.push({
        path: `/${path}`
      })
    },

    loadChannelList(){
      if(this.currency == "IDR" || this.currency == null) {
        this.channelList.push(require("../assets/image/pc/footer/dana.png"),
            require("../assets/image/pc/footer/ovo.png"),
            require("../assets/image/pc/footer/shopeepay.png"),
            require("../assets/image/pc/footer/linkaja.png"),
            require("../assets/image/pc/footer/qris.png"),
            require("../assets/image/pc/footer/astrapay.png"),
            require("../assets/image/pc/footer/bca.png"),
            require("../assets/image/pc/footer/bni.png"),
            require("../assets/image/pc/footer/bri.png"),
            require("../assets/image/pc/footer/cimb.png"),
            require("../assets/image/pc/footer/mandiri.png"),
            require("../assets/image/pc/footer/permata.png"),
            require("../assets/image/pc/footer/alfamart.png"),
            require("../assets/image/pc/footer/credit.png"))

        this.channelMobileList.push(
            require("../assets/image/wap/footer/dana.png"),
            require("../assets/image/wap/footer/ovo.png"),
            require("../assets/image/wap/footer/shopeepay.png"),
            require("../assets/image/wap/footer/linkaja.png"),
            require("../assets/image/wap/footer/qris.png"),
            require("../assets/image/wap/footer/astrapay.png"),
            require("../assets/image/wap/footer/bca.png"),
            require("../assets/image/wap/footer/bni.png"),
            require("../assets/image/wap/footer/bri.png"),
            require("../assets/image/wap/footer/cimb.png"),
            require("../assets/image/wap/footer/mandiri.png"),
            require("../assets/image/wap/footer/permata.png"),
            require("../assets/image/wap/footer/alfamart.png"),
            require("../assets/image/wap/footer/credit.png"))

      } else if(this.currency == "THB") {
        this.channelList.push(require("../assets/image/pc/footer/bay.png"),
            require("../assets/image/pc/footer/bbl.png"),
            require("../assets/image/pc/footer/credit.png"),
            require("../assets/image/pc/footer/kbank.png"),
            require("../assets/image/pc/footer/ktb.png"),
            require("../assets/image/pc/footer/linepay.png"),
            require("../assets/image/pc/footer/promptpay.png"),
            require("../assets/image/pc/footer/scb.png"),
            require("../assets/image/pc/footer/shopee.png"),
            require("../assets/image/pc/footer/truemoney.png"))

        this.channelMobileList.push(require("../assets/image/wap/footer/bay.png"),
            require("../assets/image/wap/footer/bbl.png"),
            require("../assets/image/wap/footer/credit.png"),
            require("../assets/image/wap/footer/kbank.png"),
            require("../assets/image/wap/footer/ktb.png"),
            require("../assets/image/wap/footer/linepay.png"),
            require("../assets/image/wap/footer/promptpay.png"),
            require("../assets/image/wap/footer/scb.png"),
            require("../assets/image/wap/footer/shopee.png"),
            require("../assets/image/wap/footer/truemoney.png"))
      } else if(this.currency == "PHP") {
        this.channelList.push(require("../assets/image/pc/footer/7eleven.png"),
            require("../assets/image/pc/footer/bpi.png"),
            require("../assets/image/pc/footer/cebuana.png"),
            require("../assets/image/pc/footer/gcash.png"),
            require("../assets/image/pc/footer/grabpay.png"),
            require("../assets/image/pc/footer/paymaya.png"),
            require("../assets/image/pc/footer/shopee.png"),
            require("../assets/image/pc/footer/credit.png"),
            require("../assets/image/pc/footer/ubp.png"))

        this.channelMobileList.push(require("../assets/image/wap/footer/7eleven.png"),
            require("../assets/image/wap/footer/bpi.png"),
            require("../assets/image/wap/footer/cebuana.png"),
            require("../assets/image/wap/footer/gcash.png"),
            require("../assets/image/wap/footer/grabpay.png"),
            require("../assets/image/wap/footer/paymaya.png"),
            require("../assets/image/wap/footer/shopee.png"),
            require("../assets/image/wap/footer/credit.png"),
            require("../assets/image/wap/footer/ubp.png"))
      } else if(this.currency == "BRL") {
        this.channelList.push(require("../assets/image/pc/footer/ame.png"),
            require("../assets/image/pc/footer/bank_transfer.png"),
            require("../assets/image/pc/footer/boleto.png"),
            require("../assets/image/pc/footer/mercado_pago.png"),
            require("../assets/image/pc/footer/paypal.png"),
            require("../assets/image/pc/footer/picpay.png"),
            require("../assets/image/pc/footer/pix.png"))

        this.channelMobileList.push(require("../assets/image/wap/footer/ame.png"),
            require("../assets/image/wap/footer/bank_transfer.png"),
            require("../assets/image/wap/footer/boleto.png"),
            require("../assets/image/wap/footer/mercado_pago.png"),
            require("../assets/image/wap/footer/paypal.png"),
            require("../assets/image/wap/footer/picpay.png"),
            require("../assets/image/wap/footer/pix.png"))
      } else if(this.currency == "MXN") {
        this.channelList.push(require("../assets/image/pc/footer/bank_transfer.png"),
            require("../assets/image/pc/footer/oxxo.png"),
            require("../assets/image/pc/footer/paycash.png"))

        this.channelMobileList.push(require("../assets/image/wap/footer/bank_transfer.png"),
            require("../assets/image/wap/footer/oxxo.png"),
            require("../assets/image/wap/footer/paycash.png"))
      } else if(this.currency == "VND") {
        this.channelList.push(require("../assets/image/pc/footer/woori.png"),
            require("../assets/image/pc/footer/momo.png"),
            require("../assets/image/pc/footer/viettelpay.png"),
            require("../assets/image/pc/footer/vnptpay.png"),
            require("../assets/image/pc/footer/shopee.png"),
            require("../assets/image/pc/footer/appota.png"),
            require("../assets/image/pc/footer/zalopay.png"))

        this.channelMobileList.push(require("../assets/image/wap/footer/woori.png"),
            require("../assets/image/wap/footer/momo.png"),
            require("../assets/image/wap/footer/viettelpay.png"),
            require("../assets/image/wap/footer/vnptpay.png"),
            require("../assets/image/wap/footer/shopee.png"),
            require("../assets/image/wap/footer/appota.png"),
            require("../assets/image/wap/footer/zalopay.png"))
      } else if(this.currency == "MYR") {
        this.channelList.push(require("../assets/image/pc/footer/tng.png"),
            require("../assets/image/pc/footer/mcash.png"),
            require("../assets/image/pc/footer/boost.png"),
            require("../assets/image/pc/footer/grabpay.png"),
            require("../assets/image/pc/footer/fpx.png"),
            require("../assets/image/pc/footer/unionpay.png"))

        this.channelMobileList.push(require("../assets/image/wap/footer/tng.png"),
            require("../assets/image/wap/footer/mcash.png"),
            require("../assets/image/wap/footer/boost.png"),
            require("../assets/image/wap/footer/grabpay.png"),
            require("../assets/image/wap/footer/fpx.png"),
            require("../assets/image/wap/footer/unionpay.png"))
      }
    }
  },
}
</script>

<style lang="scss" scoped>
a:hover {
  text-decoration: none;
  opacity: 0.8;
}
img {
  width: 100%;
  height: 100%;
}
p {
  margin-bottom: 0;
}
.footer {
  max-width: 1236px;
  margin: 0 auto;
}
.footer-part1 {
  text-align: left;
  display: flex;
  justify-content: space-between;
  .footer-logo-content {
    min-width: 450px;
  }
  .logo-hd {
    height: 37px;
    width: auto;
    margin-bottom: 16px;
  }
  .contact-more {
    max-width: 503px;
    margin-left: 94px;
  }
  .footer-part1-2 {
    display: flex;
    margin-left: 116px;
  }
  ul {
    margin-bottom: 0;
    li {
      margin-bottom: 15px;
      color: #999;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        line-height: 25px;;
      }
    }
  }
  .more-wrapper {
    min-width: 200px;
    a {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999;
      line-height: 25px;
    }
    .title {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #fff;
      line-height: 25px;
      margin-bottom: 24px;
    }
  }
  .channel-more {
    width: 232px;
  }
  .channel-lists {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 68px;
      height: 34px;
      margin: 0 8px 8px 0;
      &:nth-child(6n) {
        margin-right: 0;
      }
    }
  }
}
.footer-part2 {
  padding-top: 16px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999;
  line-height: 20px;

}
.mobile {
  .footer-part1 {
    font-size: px2rem(24);
    display: block;

    .footer-logo-content {
      min-width: auto;
      .logo-hd {
        height: .45rem;
        margin-bottom: .35rem;
      }
    }
    
    .title {
      font-size: px2rem(28);
      margin-bottom: px2rem(41);
      margin-top: .3rem;
    }
    .footer-part1-2 {
      display: block;
      margin-left: 0;
    }
    .channel-lists {
      li {
        margin-right: .1rem;
        margin-left: 0;
        margin-bottom: .4rem;
        height: .49rem;
        width: auto;
        a {
          font-size: .22rem;
          color: #222;
        }
      }
    }
    .contact-more {
      min-width: auto;
      width: 100%;
      margin-left: 0;;
      margin-right: 0;
      .contact-lists {
        li {
          margin-bottom: .32rem;
          &:last-child {
            margin-bottom: px2rem(0);
          }
        }
      }
    }
  }
  .footer-part2 {
    font-size: px2rem(22);
    font-family: PingFang SC;
    font-weight: 400;
    color: #A0A0A0;
    padding: 0;
    border: none;
    text-align: left;
    padding-top: .38rem;
    border-top: .01rem solid rgba(227, 227, 227, .15);
    margin-top: .51rem;
  }
  .footer-part3 {
    .links-more-lists {
      display: flex;
      font-size: px2rem(24);
      font-family: PingFang SC;
      font-weight: 400;
      justify-content: center;
      line-height: 1;
      margin-bottom: 0;
      margin-top: px2rem(21);
      li {
        border-left: 1px solid #2E2E2E;
        &:first-child {
          border-left: none;
        }
      }
      a {
        color: #FFFFFF;
        margin: 0 px2rem(20);
        white-space: nowrap;
      }
    }
  }

}

</style>
